import React, { useState } from 'react';
import LocationLayout from '../components/locationLayout';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import toast, { Toaster } from 'react-hot-toast'; // 🔥 Import du toast

const Event = ({ pageContext }) => {
  const product = pageContext.post;
  const name = pageContext.post.data.name.text;
  const description = pageContext.post.data.description.html;
  const image = getImage(pageContext.post.data.image);
  const stock = pageContext.post.data.stock;

  const [quantity, setQuantity] = useState(1);

  const generateOptions = (stock) => {
    let step = 1;
    if (stock > 40) {
      step = 5;
    } else if (stock > 20) {
      step = 2;
    }
    
    return Array.from({ length: Math.ceil(stock / step) }, (_, i) => (i + 1) * step);
  };
  const options = generateOptions(stock);

  const addToCart = () => {
    const cart = JSON.parse(localStorage.getItem('cart')) || [];
    const existingItem = cart.find(item => item.product.id === product.id);
    if (existingItem) {
      existingItem.quantity = quantity;
    } else {
      cart.push({ product, quantity });
    }
  
    localStorage.setItem('cart', JSON.stringify(cart));

    toast.success(`${quantity} x "${name}" ajouté au panier ! 🛒`);
  };

  return (
    <LocationLayout>

      <Toaster position="top-right" reverseOrder={false} />

      <div className="location-home w-[1500px] max-w-[80%] mx-auto">
        
        {/* NAME */}
        <h1 className="text-3xl font-bold !ml-0 !text-left !leading-[90px]">{name}</h1>

        <div className="md:max-w-[80%] max-w-[95%] mx-auto flex flex-col items-center">  
          <div className="text-center">

            {/* IMAGE */}
            <div className="flex justify-center mt-9">
              <div className="w-full aspect-[2/1] overflow-hidden rounded-3xl">
                <GatsbyImage image={image} className="w-full h-full object-cover" />
              </div>
            </div>

            {/* NAME */}
            <h2 className="text-left mt-6">{name}</h2>

            {/* DESCRIPTION */}
            <div className="mt-2 mb-9 text-justify" dangerouslySetInnerHTML={{ __html: description }} />

            {/* QUANTITY SELECTION & BUTTON */}
            <div className="flex items-center gap-4 my-4 h-9">
              <span className="font-medium font-openSans">Qté</span>
              <div className="relative">
                <select  
                  className="border rounded pl-3 mx-2 py-1 bg-black text-white appearance-none pr-5 font-poppins text-sm font-extrabold"
                  value={quantity} 
                  onChange={(e) => setQuantity(Number(e.target.value))} >
                  {options.map(value => (
                    <option key={value} value={value}>{value}</option>
                  ))}
                </select>
                <div className="absolute right-2 top-1/2 transform -translate-y-1/2 pointer-events-none">
                  <svg className="w-4 h-4 fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
                  </svg>
                </div>
              </div>
              <button className="" onClick={addToCart}>
                Ajouter au panier
              </button>
            </div>
          </div>

          <div className='flex'>
            <form action={"/location/panier"}>
              <button className="h-12 w-48 my-12 mr-3">
                Voir le panier
              </button>
            </form>
            <form action={"/location/produits"}>
              <button className="h-12 w-48 my-12 ml-3">
                Retour
              </button>
            </form>
          </div>

        </div>
      </div>
    </LocationLayout>
  );
};

export default Event;